<template>
  <div class="shop-agents">
    <b-card>
      <div class="row">
        <div v-for="(headerLabel, hk) in headerLabels" class="col-12 col-md-3" :key="`hk_${hk}`">
          <HeaderCard 
            :counter="headerLabel.count"
            :img="headerLabel.img"
            :label="headerLabel.label"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-md-right">
          <small class="text-secondary">
            This data is updated every 24 hours, or you can refresh it now by clicking the update button.
            <b-button variant="outline-primary" @click="refreshData" class="ml-1" size="sm">Update</b-button>
          </small>
        </div>
      </div>
    </b-card>
    <b-card>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group :label="$t('From Date')">
            <b-form-datepicker v-model="dataInfo.fromDate" locale="en" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group :label="$t('To Date')">
            <b-form-datepicker v-model="dataInfo.toDate" locale="en" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4" class="mt-auto mb-1">
          <b-button variant="primary" @click="loadSlaReport">
            {{ $t('Search') }}
          </b-button>
        </b-col>
        <b-col cols="12">
          <good-data-table ref="orderGrid" :columns="orderColumns" :total-column="'response.responseData.total'"
            :data-column="`Object.values(response.responseData.data)`" :api-endpoint="'/sla-reports'" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import moment from 'moment'
import { isEmpty } from '@/utilities'
import { mapActions } from 'vuex'
import { hideLoader, showLoader } from '@/@core/comp-functions/ui/app';
import HeaderCard from '@/components/common/HeaderCard.vue';
export default {
  components: {
    GoodDataTable,
    HeaderCard
  },
  data() {
    return {
      dataInfo: {
        fromDate: '',
        toDate: '',
      },
      headerLabels: [],
      orderColumns: [
        {
          label: 'Order Id',
          field: 'store_order_invoice_id',
        },
        {
          label: 'Current Status',
          field: 'status_label',
        },
        {
          label: 'Accepted',
          field: 'accepted',
        },
        {
          label: 'Assigned',
          field: 'assigned',
        },
        {
          label: 'Started',
          field: 'started',
        },
        {
          label: 'Reached',
          field: 'reached',
        },
        {
          label: 'Pickedup',
          field: 'pickedup',
        },
        {
          label: 'Out for Delivery',
          field: 'out_for_delivery',
        },
        {
          label: 'Arrived',
          field: 'arrived',
        },
        {
          label: 'Completed',
          field: 'completed',
        }
      ]
    }
  },
  methods: {
    ...mapActions('store', ['getSlaReportStatatics', 'updaadteSlaReportStatatics']),
    loadSlaReport() {
      const dataPayload = []
      if (this.dataInfo.fromDate) {
        dataPayload.push(`from=${moment(this.dataInfo.fromDate).format('YYYY-MM-DD')}`)
      }
      if (this.dataInfo.toDate) {
        dataPayload.push(`to=${moment(this.dataInfo.toDate).format('YYYY-MM-DD')}`)
      }
      this.$refs.orderGrid.loadItems(dataPayload.join('&'))
    },
    refreshData(){
      showLoader()
      this.updaadteSlaReportStatatics().then(r => {
        this.headerLabels = r
        hideLoader()
      }).catch(e => {
        hideLoader()
      })
    },
    getStatitics() {
      this.headerLabels = []
      const params = {}
      if (!isEmpty(this.dataInfo.fromDate)) {
        params.from = moment(this.dataInfo.fromDate).format('YYYY-MM-DD')
      }
      if (!isEmpty(this.dataInfo.toDate)) {
        params.to = moment(this.dataInfo.toDate).format('YYYY-MM-DD')
      }
      this.getSlaReportStatatics(params).then(r => {
        this.headerLabels = r
      })
    }
  },
  mounted() {
    this.getStatitics()
  }
}
</script>

<style>
.agent-image-info {
  width: 40px;
  height: 40px;
  border-radius: 10%;
  margin-right: 10px;
}
</style>
